import React, { useState, useEffect } from "react";
import useLocalStorage from "../../../components/useLocalStorage";
import moment from "moment-timezone";
import GenreDropDownList from "../../../components/GenreDropDownList";
import isEmpty from "../../../components/isEmpty";
import { SliderPicker } from "react-color";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toggle from "react-toggle";
import "react-toggle/style.css"; // for ES6 modules
//API
import { get_music_genres } from "./radio_api";

//JS
import CreateBroadcastServer from "./server/CreateBroadcastServer";
import RadioImages from "./RadioImages";
import TimezoneSelector from "./TimezoneSelector";
import PayPalTicketBtn from "./paypal/PayPalTicketBtn";
import PayPalOnboarding from "./paypal/PayPalOnboarding";

//CSS
import "./RadioItemCreate.css";
import TicketPricing from "./paypal/TicketPricing";

//RadioItemCreate class
function RadioItemCreate(props) {
  //start_date,end_date
  const [start_date, setStartDate] = useState(moment().add(0, "hour"));
  const [end_date, setEndDate] = useState(moment().add(1, "hour"));
  const [timezone, setTimezone] = useState(moment.tz.guess());

  const [startDate, _setStartDate] = useState(new Date());

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  //
  //ViewDidLoad
  useEffect(() => {
    //get all user broadcast
  }, []);

  //
  async function handleColorPicker(color) {
    props.updateBroadcast("bgColor", color.hex);
  }

  async function updateTags(tags) {
    props.updateBroadcast("tags", tags);
  }

  async function updateGenres(genres) {
    props.updateBroadcast("genres", genres);
  }

  async function updateTitle(text) {
    props.updateBroadcast("title", text);
  }

  async function updateDescription(text) {
    props.updateBroadcast("description", text);
  }

  async function updateURL(text) {
    props.updateBroadcast("url", text);
  }

  async function updateGroup(text) {
    props.updateBroadcast("group_id", text);
  }

  async function updateStartTime(value) {
    //CHECK
    if (
      !isDateBefore(value, props.broadcast.end) &&
      !isEmpty(props.broadcast.end)
    ) {
      alert("Start date has to be earlier than end date");
      return;
    }

    var selected_start_date = moment.tz(value, timezone);
    var losAngeles = selected_start_date.clone().tz("America/Los_Angeles");
    var london = selected_start_date.clone().tz("Europe/London");

    let formatted_start_date = selected_start_date.format(); // 2014-06-01T12:00:00-04:00
    let la_start_date = losAngeles.format(); // 2014-06-01T09:00:00-07:00
    let london_start_date = london.format(); // 2014-06-01T17:00:00+01:00

    console.log("selected_start_date", formatted_start_date);
    console.log("la_start_date", la_start_date);
    console.log("london_start_date", london_start_date);

    props.updateBroadcast("start_time", selected_start_date);
    props.updateBroadcast("start", new Date(selected_start_date));
  }

  async function updateEndTime(value) {
    //CHECK
    if (
      isDateBefore(value, props.broadcast.start) &&
      !isEmpty(props.broadcast.start)
    ) {
      alert("Start date has to be earlier than end date");
      return;
    }
    var selected_end_date = moment.tz(value, timezone);
    var losAngeles = selected_end_date.clone().tz("America/Los_Angeles");
    var london = selected_end_date.clone().tz("Europe/London");

    let formatted_start_date = selected_end_date.format(); // 2014-06-01T12:00:00-04:00
    let la_start_date = losAngeles.format(); // 2014-06-01T09:00:00-07:00
    let london_start_date = london.format(); // 2014-06-01T17:00:00+01:00

    console.log("selected_end_date", formatted_start_date);
    console.log("la_start_date", la_start_date);
    console.log("london_start_date", london_start_date);

    props.updateBroadcast("end_time", selected_end_date);
    props.updateBroadcast("end", new Date(selected_end_date));
  }

  function saveBroadcast(e) {
    e.preventDefault();
    props.saveBroadcast();
  }

  function liveBroadcast(e) {
    e.preventDefault();
    props.liveBroadcast();
  }

  function deleteBroadcast(e) {
    e.preventDefault();
    props.deleteBroadcast();
  }

  function selectTimeZone(timezone) {
    //change the timezone for
    console.log("timezone", timezone);
    setTimezone(timezone);
    let start = moment(props.broadcast.start_time)
      .tz(timezone)
      .format("dddd, MMMM Do YYYY, h:mm:ss a");
    let end = moment(props.broadcast.end_time)
      .tz(timezone)
      .format("dddd, MMMM Do YYYY, h:mm:ss a");

    //update start_time
    //update end_time
  }

  //updateTicketPrice
  const handlePriceChange = (newPrice) => {
    console.log("Price changed to:", newPrice);
    props.updateBroadcast("current_price", newPrice);
    // Perform any additional actions needed when the price changes
  };

  //VIEW
  return (
    <div className="c_broadcast">
      <div className="new_broadcast">
        <div>
          <div className="edit_title">
            <strong>EDIT BROADCAST</strong>
          </div>
          <div className="submit_btn">
            {/* <button
              onClick={(e) => liveBroadcast(e)}
              disabled={props.isApiWorking}
            >
              LIVE NOW
            </button> */}
            <button
              onClick={(e) => saveBroadcast(e)}
              disabled={props.isApiWorking}
            >
              SAVE
            </button>
            <button
              onClick={(e) => deleteBroadcast(e)}
              disabled={props.isApiWorking}
            >
              DELETE
            </button>
          </div>
        </div>
        <div>
          {/* PREMIER TOGGLE */}
          <div>
            <label className="premier-toggle">
              <Toggle
                defaultChecked={props.broadcast.tags.includes("PREMIER")}
                onChange={(e) => updateTags(e.target.value ? "PREMIER" : "")}
              />
              <span>Premier</span>
            </label>
          </div>
          <div>
            <input
              value={props.broadcast.title}
              onChange={(e) => updateTitle(e.target.value)}
              placeholder="Broadcast Title"
            />
          </div>
          <div>
            <div className="_subt">DESCRIPTION</div>
            <textarea
              value={props.broadcast.description}
              onChange={(e) => updateDescription(e.target.value)}
              placeholder="Description"
            />
          </div>
          {/* BROADCAST IMAGES */}
          <RadioImages user={props.user} broadcast={props.broadcast} />
          {/* 1. REGISTER MERCHANT - PAYPAL MERCHANT ONBOARDING */}
          <PayPalOnboarding user={props.user} broadcast={props.broadcast} />
          {/* 2. SET PAYPAL TICKET PRICE - PAYPAL MERCHANT TICKET PRICING */}
          <TicketPricing
            user={props.user}
            broadcast={props.broadcast}
            onPriceChange={handlePriceChange}
          />
          {/* DATE PICKER */}
          <div>
            <div className="_subt">
              {`DURATION ${getEventDuration(
                props.broadcast.start,
                props.broadcast.end
              )}`}{" "}
              hrs
            </div>
            <div>
              <div className="start-time-span">Start</div>
              <DatePicker
                showIcon
                // readOnly={props.broadcast.is_published === 1}
                showTimeSelect
                filterTime={filterPassedTime}
                dateFormat="yyyy-MM-dd HH:mm:ss"
                placeholderText="Start Time"
                selected={
                  isEmpty(props.broadcast.start) ? null : props.broadcast.start
                }
                onChange={(date) => updateStartTime(date)}
              />
            </div>
            <div>
              <div className="end-time-span">End</div>
              <DatePicker
                showIcon
                // readOnly={props.broadcast.is_published === 1}
                showTimeSelect
                filterTime={filterPassedTime}
                dateFormat="yyyy-MM-dd HH:mm:ss"
                placeholderText="End Time"
                selected={
                  isEmpty(props.broadcast.end) ? null : props.broadcast.end
                }
                onChange={(date) => updateEndTime(date)}
                utcOffset={moment().utcOffset()}
              />
            </div>
            <div>
              <TimezoneSelector
                start_time={props.broadcast.start_time}
                end_time={props.broadcast.end_time}
                selectTimeZone={selectTimeZone}
              />
            </div>
          </div>
          {/* CREATE BROADCAST */}
          <div>
            <div>
              <br />
              <p>
                In order to broadcast, you will need a server, a client and
                client connection details.
              </p>
              <p>
                Once your server has been created, you can use a client to
                connect.{" "}
              </p>
            </div>
            <CreateBroadcastServer
              user={props.user}
              broadcast={props.broadcast}
            />
            <div className="_subt">
              {"🎤 "}
              <i className="fa fa-chevron-right" />
              {` Broadcast URL `}
              <i className="fa fa-chevron-right" />
              {" 🔊"}
            </div>
            <div className="upstream">
              <div>Stream Up URL is where you broadcast up</div>
              <input
                value={props.broadcast.url}
                onChange={(e) => updateURL(e.target.value)}
                placeholder="Stream Up URL"
              />
            </div>
            <div className="listenerstream">
              <div>
                Direct Listener URL is where listeners can listen you (Don't
                share this link, if it's a paid event)
              </div>
              <input
                value={props.broadcast.url}
                onChange={(e) => updateURL(e.target.value)}
                placeholder="Direct Listener URL"
              />
            </div>
            <div className="valid_url">
              {!isMP3URLValid(props.broadcast.url) && (
                <div>Please enter a valid URL link ending with .mp3</div>
              )}
            </div>
          </div>
          {/* COLORS/THEMES */}
          <div className="_c_color">
            <div className="_subt">COLORS</div>
            <SliderPicker
              color={props.broadcast.bgColor}
              onChangeComplete={handleColorPicker}
            />
          </div>
          <div>
            <div className="_subt">BROADCAST GROUP</div>
            <select
              value={props.broadcast.group_id}
              onChange={(e) => updateGroup(e.target.value)}
            >
              {get_music_genres().map((item, index) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <div className="_subt">GENRES</div>{" "}
            <GenreDropDownList
              genres={props.broadcast.genres}
              setGenres={updateGenres}
            />
          </div>
        </div>
      </div>
      {/* DISCLAIMER */}
      <div className="disclaimer">
        <strong>Disclaimer:</strong> The content broadcasted through this app
        should not infringe any copyright laws. Users are solely responsible for
        compliance with applicable laws regarding their content.
      </div>
    </div>
  );

  //IS.URL.Valid tells if the entered String is a valid URL
  function isMP3URLValid(url) {
    // Check if URL is valid
    try {
      new URL(url);
    } catch (e) {
      return false;
    }

    // Check if URL ends with ".mp3"
    return url.endsWith(".mp3");
  }

  function getEventDuration(start, end) {
    if (isEmpty(start) || isEmpty(end)) {
      return "";
    }
    const diffInMinutes = Math.floor((end - start) / (1000 * 60));
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  function isDateBefore(start, end) {
    const diffInMinutes = Math.floor((end - start) / (1000 * 60));
    return diffInMinutes > 0 ? true : false;
  }
}

export default RadioItemCreate;

import { Auth } from "aws-amplify";
const AwsConfig = require("../AWSConfig");

//CHANGE_PASSWORD
export const changePass = async (oldPass, newPass) => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.changePassword(user, oldPass, newPass);
      })
      .then((_) => {
        resolve("PASSWORD_CHANGED_SUCCESS");
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//GET_AUTHORIZATION_TOKEN
export const getIDToken = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((res, err) => {
        if (err) {
          reject(err);
        }
        let idtoken = res.getIdToken().getJwtToken();
        let accessToken = res.getAccessToken();
        let a_jwt = accessToken.getJwtToken();
        var params = {
          AccessToken: a_jwt,
          IDToken: idtoken,
        };
        resolve(params);
      })
      .catch((error) => {
        if (error.code === "TooManyRequestsException") {
          // Rate limit exceeded, display a user-friendly message
          alert(
            "Too many requests have been made. Please wait a few moments before trying again."
          );
        } else {
          alert("An unexpected error occurred. Please try again later.");
        }
        reject(error);
      });
  });
};

//GET_USER_ID
export const getUserID = async () => {
  return new Promise((resolve, reject) => {
    Auth.currentUserInfo().then((res, err) => {
      if (res.attributes) {
        let userId = res.attributes.sub;
        //You can print them to see the full objects
        // console.log(`USER_ID: ${JSON.stringify(userId)}`);
        resolve(userId);
      } else {
        reject("USER_ID NOT FOUND");
      }
      if (err) {
        reject(err);
      }
    });
  });
};

import React, { useState, useEffect } from "react";
import isEmpty from "../../../../components/isEmpty";
import CloudResourceStepper from "./CloudResourceStepper";
//API
import {
  createServerAPI,
  checkServerStatusAPI,
  resendBroadcastingDetailsAPI,
} from "../../../../utils/apis/live/auth_live_broadcasts";
//
import about from "../../../../assets/about/about.json";
import AddBroadcastImage from "./AddBroadcast.png";
import AddStationImage from "./AddStation.png";
import AddMailImage from "./AddMail.png";
import ServerOKImage from "./ServerOK.png";
import ServerFailImage from "./ServerFail.png";

//CD
import "./CreateBroadcastServer.css";

function CreateBroadcastServer(props) {
  const [isApiWorking, setIsApiWorking] = useState(false);
  const [showStepper, setShowStepper] = useState(false);
  const [broadcast_server, setBroadcastServer] = useState(null);
  //

  //ViewDidLoad
  useEffect(() => {
    // console.log(props.broadcast);
    //if broadcat server is in state of starting check the server status than recheck it again after 2 mins
    if (!isEmpty(props.broadcast["broadcast_server"])) {
      setBroadcastServer(props.broadcast["broadcast_server"]);
      if (props.broadcast["broadcast_server"]["server_status"] === "starting") {
        checkServerStatus();
        serverStatusShouldCheckAgain();
      }
    }
  }, []);

  //Refresh server status after 2 min
  function serverStatusShouldCheckAgain() {
    const timeoutId = setTimeout(() => {
      checkServerStatus(); // This will run after 2 minutes
    }, 120000); // 120000ms = 2 minutes

    return () => clearTimeout(timeoutId); // Clean up on unmount
  }

  //Creating Broadcasting server for this broadcast
  async function createNewBroadcastServer(e) {
    e.preventDefault();
    //handle broadcast_server in creation
    if (
      !isEmpty(props.broadcast["broadcast_server"]) &&
      props.broadcast["broadcast_server"]["server_status"] === "starting"
    ) {
      alert(
        "Server is being created. Please wait for your confirmation email.If an error occurs, you will be able to create a new server."
      );
      return;
    }
    if (
      !isEmpty(props.broadcast["broadcast_server"]) &&
      props.broadcast["broadcast_server"]["server_status"] === "running"
    ) {
      alert(
        "Server is up and running. Please click on 3.Connection Details to receive login details via email"
      );
      return;
    }
    setIsApiWorking(true);
    setShowStepper(true);
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`id=${props.broadcast.id}`);
      let bParameters = bodyParams.join("&");
      let result = await createServerAPI(bParameters);
      console.log("RESULT", result);
      setBroadcastServer(result["broadcast_server"]);
      setIsApiWorking(false);
      setShowStepper(false);
      //once status has been initiated start to check server status
    } catch (error) {
      setIsApiWorking(false);
      setShowStepper(false);
      console.log(error);
      alert(error.message);
    }
  }

  //Checking server status to inditate on front-end
  async function checkServerStatus() {
    setIsApiWorking(true);
    setShowStepper(true);
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`id=${props.broadcast.id}`);
      let bParameters = bodyParams.join("&");
      let result = await checkServerStatusAPI(bParameters);
      console.log("RESULT", result);
      setBroadcastServer(result["broadcast_server"]);
      let server_status = result["broadcast_server"]["server_status"];
      if (server_status == "starting") {
        setIsApiWorking(true);
        setShowStepper(true);
      }
      if (server_status != "starting") {
        setIsApiWorking(false);
        setShowStepper(false);
      }

      //once status has been initiated start to check server status
    } catch (error) {
      setIsApiWorking(false);
      setShowStepper(false);
      console.log(error);
      alert(error.message);
    }
  }

  //Resend Connection Details via Email to broadcaster
  async function resendConnectionDetails(e) {
    e.preventDefault();
    setIsApiWorking(true);
    setShowStepper(false);
    try {
      var queryParams = [];
      queryParams.push(`user_id=${props.user.user_id}`);
      queryParams.push(`token=${props.user.edit_token}`);
      queryParams.push(`id=${props.broadcast.id}`);
      let qParameters = queryParams.join("&");
      let result = await resendBroadcastingDetailsAPI(qParameters);
      console.log("RESULT", result);
      alert(result["message"]);
      if (broadcast_server["server_status"] === "starting") {
        setIsApiWorking(true);
        setShowStepper(true);
      } else {
        setIsApiWorking(false);
        setShowStepper(false);
      }
    } catch (error) {
      if (broadcast_server["server_status"] === "starting") {
        setIsApiWorking(true);
        setShowStepper(true);
      } else {
        setIsApiWorking(false);
        setShowStepper(false);
      }
      console.log(error);
      alert(error.message);
    }
  }

  //VIEW
  return (
    <div className="create_broadcasts_">
      <form onSubmit={createNewBroadcastServer}>
        <table className="create_broadcasts__table">
          <tbody>
            <tr>
              <td>
                {isEmpty(broadcast_server) && (
                  <a
                    className="create_broadcasts__item"
                    href={""}
                    target="_blank"
                    onClick={createNewBroadcastServer}
                  >
                    <img
                      src={AddBroadcastImage}
                      alt="Create Streaming Server"
                      className="broadcast_image"
                    />
                    <div className="broadcast_text">
                      1. Create Streaming Server
                    </div>
                  </a>
                )}
                {!isEmpty(broadcast_server) &&
                  broadcast_server["server_status"] == "running" && (
                    <div
                      className="create_broadcasts__item"
                      href={""}
                      target="_blank"
                    >
                      <img
                        src={ServerOKImage}
                        alt="Create Streaming Server"
                        className="broadcast_image"
                      />
                      <div
                        className="broadcast_text"
                        style={{ fontSize: "14px" }}
                      >
                        {broadcast_server["title"]} (Server Running) until{" "}
                        {broadcast_server["end_time"]}
                      </div>
                    </div>
                  )}
                {!isEmpty(broadcast_server) &&
                  broadcast_server["server_status"] == "failed" && (
                    <div
                      className="create_broadcasts__item"
                      href={""}
                      target="_blank"
                    >
                      <img
                        src={ServerFailImage}
                        alt="Create Streaming Server"
                        className="broadcast_image"
                      />
                      <div
                        className="broadcast_text"
                        style={{ fontSize: "14px" }}
                      >
                        Error: Server creation failed
                      </div>
                    </div>
                  )}
              </td>
            </tr>
            <tr>
              <td>
                <a
                  className="create_broadcasts__item"
                  href={about.radio_app_url}
                  target="_blank"
                >
                  <img
                    src={AddStationImage}
                    alt="Download Streaming Client"
                    className="broadcast_image"
                  />
                  <div className="broadcast_text">
                    2. Download Streaming Client[iOS]
                  </div>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  className="create_broadcasts__item"
                  href={""}
                  target="_blank"
                  onClick={resendConnectionDetails}
                >
                  <img
                    src={AddMailImage}
                    alt="Connection Details"
                    className="broadcast_image"
                  />
                  <div className="broadcast_text">3. Connection Details</div>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          type="submit"
          className="create_broadcasts__button"
          disabled={isApiWorking}
        >
          {isApiWorking
            ? showStepper
              ? "Creating server..."
              : "Sending email..."
            : "Create Server"}
        </button>
      </form>
      {isApiWorking && showStepper && (
        <div className="slideloader">
          <div className="slide-in-than-out">
            <div className="dev-msg">You will receive an email</div>
          </div>
          <div className="slide-out-then-in">
            <CloudResourceStepper />
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateBroadcastServer;

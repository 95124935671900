import React, { useEffect } from "react";

// SignUpForPayPalMerchant Component
function SignUpForPayPalMerchant({ action_url }) {
  useEffect(() => {
    const scriptId = "paypal-js";
    const script = document.createElement("script");
    script.id = scriptId;
    script.async = true;
    script.src =
      "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
    // Insert script before the first existing script tag
    const firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(script, firstScript);
  }, []); // Run only once on component mount

  return (
    <div dir="ltr" style={{ textAlign: "left" }}>
      <a
        data-paypal-button="true"
        href={`${action_url}&displayMode=minibrowser`}
        target="PPFrame"
      >
        Connect with PayPal
      </a>
    </div>
  );
}

export default SignUpForPayPalMerchant;

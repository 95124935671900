import React, { useEffect, useState } from "react";
import Footer from "../../../../components/Footer/Footer";
import isEmpty from "../../../../components/isEmpty";
import validateImageFile from "../../../../components/imageValidator";
import { fileToS3 } from "../../../../utils/apis/s3/UploadImage";
//API
import {
  createAdvertToBroadcastAPI,
  deleteAdvertForBroadcastAPI,
  saveAdvertLaterAPI,
  deleteSavedAdvertAPI,
} from "../../../../utils/apis/live/auth_live_broadcasts";
import {
  get_music_genres,
  ad_buttons,
} from "../../../../utils/apis/api/public";

//PAYPAL
import PayPalPayForAdButton from "./paypal/PayPalPayForAdButton";

//CSS
import "./Advertise.css"; // Import your CSS file
import ScheduledAdsTable from "./ScheduledAdsTable";
import AdPreview from "./AdPreview";

const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//consts
const maxDesc = 100;
const maxTitle = 100;

//Advertise
const Advertise = (props) => {
  //
  const [isApiWorking, setIsApiWorking] = useState(false);
  //
  var [genres, setGenres] = useState([]);
  var [cat_list, setCATList] = useState([]);
  var [post_time, setPostTime] = useState([
    "Now",
    "Start of Event",
    "End of Event",
  ]);
  var [form, setForm] = useState(false);
  var [audio_url, setAudioURL] = useState("");
  var [price, setPrice] = useState(50);

  // Price range data
  const priceRanges = [
    { duration: "30 seconds", price: "£50" },
    { duration: "1 minute", price: "£80" },
  ];

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    email: "",
    title: "",
    description: "",
    action_cover_img_url: "",
    action_url: "",
    action_button: "Learn more",
    action_date: formatDate(new Date()),
    post_date: "Now",
  });

  useEffect(() => {
    //
    get_ad_button_list();
  }, []);

  //API
  async function get_ad_button_list() {
    try {
      //SUCCESS
      let result = await ad_buttons();
      setCATList(result);

      //ERROR
    } catch (error) {
      console.log(error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePostTimeChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to handle form submission and payment processing
    // This could involve sending formData to your backend API
    createAdvertToBroadcast();
  };

  const handleSaveAd = (e) => {
    e.preventDefault();
    // Logic to handle form submission and payment processing
    // This could involve sending formData to your backend API
    saveAdvertLater();
  };

  async function createAdvertToBroadcast() {
    setIsApiWorking(true);
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`broadcast_id=${props.broadcast["id"]}`);
      bodyParams.push(`title=${formData["title"]}`);
      bodyParams.push(`description=${formData["description"]}`);
      bodyParams.push(
        `action_cover_img_url=${formData["action_cover_img_url"]}`
      );
      bodyParams.push(`action_url=${formData["action_url"]}`);
      bodyParams.push(`action_button=${formData["action_button"]}`);
      bodyParams.push(`post_date=${formData["post_date"]}`);

      let bParameters = bodyParams.join("&");
      let result = await createAdvertToBroadcastAPI(bParameters);
      console.log("RESULT", result);
      setIsApiWorking(false);
      //reload window
      window.location.href = `${window.location.origin}/broadcast/${props.broadcast.id}?ad`;
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
      alert(error.message);
    }
  }

  async function saveAdvertLater() {
    setIsApiWorking(true);
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`broadcast_id=${props.broadcast["id"]}`);
      bodyParams.push(`title=${formData["title"]}`);
      bodyParams.push(`description=${formData["description"]}`);
      bodyParams.push(
        `action_cover_img_url=${formData["action_cover_img_url"]}`
      );
      bodyParams.push(`action_url=${formData["action_url"]}`);
      bodyParams.push(`action_button=${formData["action_button"]}`);
      bodyParams.push(`post_date=${formData["post_date"]}`);

      let bParameters = bodyParams.join("&");
      let result = await saveAdvertLaterAPI(bParameters);
      console.log("RESULT", result);
      setIsApiWorking(false);
      //reload window
      window.location.href = `${window.location.origin}/broadcast/${props.broadcast.id}?ad`;
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
      alert(error.message);
    }
  }

  // Function to handle ad deletion
  const handleDelete = (adIds) => {
    // Confirm deletion
    deleteScheduledAdvert(adIds);
  };

  // Function to handle ad deletion
  const handleSavedAdDelete = (adIds) => {
    // Confirm deletion
    deleteSavedAdvert(adIds);
  };

  async function deleteScheduledAdvert(adIds) {
    setIsApiWorking(true);
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`ids_to_delete=${adIds.join(",")}`);
      let bParameters = bodyParams.join("&");
      let result = await deleteAdvertForBroadcastAPI(bParameters);
      console.log("RESULT", result);
      setIsApiWorking(false);
      //reload window
      // window.location.href = `${window.location.origin}/broadcast/${props.broadcast.id}?ad`;
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
      alert(error.message);
    }
  }

  async function deleteSavedAdvert(adIds) {
    setIsApiWorking(true);
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`ids_to_delete=${adIds.join(",")}`);
      let bParameters = bodyParams.join("&");
      console.log("bParameters", bParameters);

      let result = await deleteSavedAdvertAPI(bParameters);
      console.log("RESULT", result);
      setIsApiWorking(false);
      //reload window
      window.location.href = `${window.location.origin}/broadcast/${props.broadcast.id}?ad`;
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
      alert(error.message);
    }
  }

  const handleImageFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadImage(
        file,
        `radio/${props.broadcast.id}`,
        `${props.broadcast.id}_adimg.jpg`
      );
    }
  };

  async function uploadImage(image, path, filename) {
    const respectSquareSize = true;
    try {
      // Validate the image file
      await validateImageFile(image, respectSquareSize);
      // console.log("VALID_FILE");
      // Upload the image to S3
      const uploadURL = await fileToS3(image, path, filename);
      // Update the form data with the image URL
      setFormData((prevState) => ({
        ...prevState,
        action_cover_img_url: uploadURL,
      }));
    } catch (err) {
      // Handle validation or upload errors
      // console.error("IMAGE_UPLOAD_FAILED", err.message);
      alert(err.message);
    }
  }

  const handleImageURLChange = (e) => {
    const localUrl = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      action_cover_img_url: localUrl,
    }));
  };

  const handleAutofill = (type) => {
    let newFormData = {};
    switch (type) {
      case "option1":
        const album =
          props.user.prod_albums[
            Math.floor(Math.random() * props.user.prod_albums.length)
          ];
        newFormData = {
          email: "example1@example.com",
          title: album.title,
          description: album.description,
          action_cover_img_url: album.cover_img_url,
          action_url: `https://songdrop.uk/qr/${album.album_id}`,
          action_button: "Shop Now",
          action_date: formatDate(new Date()),
        };
        break;
      case "option2":
        newFormData = {
          email: "example2@example.com",
          title: "Come to our next gig!",
          description:
            "Hello all! Our next show is coming. Here you can buy tickets",
          audioFile: null,
          action_cover_img_url:
            "https://cdn.songdrop.cloud/radio/buy-ticket.png",
          action_url: "http://example.com/action2",
          action_button: "Buy ticket",
          action_date: formatDate(new Date()),
        };
        break;
      case "option3":
        newFormData = {
          email: "example1@example.com",
          title: `@${props.user.username}`,
          description:
            "Subscribe to our email list to receive important updates of our new releases, events, live streams",
          action_cover_img_url: `${props.user.profile_img_url}`,
          action_url: `https://songdrop.uk/s/${props.user.username}`,
          action_button: "Sign Up",
          action_date: formatDate(new Date()),
        };
        break;
      default:
        break;
    }
    setFormData((prevFormData) => ({ ...prevFormData, ...newFormData }));
    //
    scrollToPreview();
  };

  // Function to handle ad click
  const handleAdClick = (ad) => {
    // Replace this with the logic to view ad details
    // alert(`Viewing details for: ${ad.title}`);
    setFormData((prevFormData) => ({ ...prevFormData, ...ad }));
    //
    scrollToPreview();
  };

  function scrollToPreview() {
    const element = document.getElementById("ad_preview_id");
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 200); // Delay in milliseconds (e.g., 200ms)
    }
  }

  //VIEW
  return (
    <div className="advertise-container">
      {/* Main content */}
      <div className="main-content">
        {/* How It Works */}
        {/* Advertising Guidelines */}
        <section className="guidelines-section">
          <div className="guidelines-content">
            <div>
              <h3>Advertising Guidelines</h3>
              <ul>
                <li>Must comply with non-discriminatory content guidelines.</li>
                <li>Should be suitable for a general audience.</li>
                <li>
                  Must align with SongDrop's values and maintain a positive user
                  experience.
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Form for uploading advertisement */}
        <section className="upload-section">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">{`Add Title`}</label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="Your advert title"
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">{`Description(${formData.description.length}/${maxDesc})`}</label>
              <textarea
                id="description"
                name="description"
                placeholder="Your advert description"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Cover Image</label>
              {!isEmpty(formData.action_cover_img_url) && (
                <img
                  alt=""
                  src={formData.action_cover_img_url}
                  className="cover_img_mini"
                />
              )}
              <input
                type="file"
                id="coverImage"
                name="coverImage"
                onChange={handleImageFileChange}
                accept="image/*"
              />
              <input
                type="text"
                id="coverImage"
                name="coverImage"
                onChange={handleImageURLChange}
                placeholder="https://..."
                value={formData.action_cover_img_url}
              />
            </div>

            <div className="form-group">
              <label htmlFor="duration">Call to Action</label>
              <select
                id="action_button"
                name="action_button"
                value={formData.action_button}
                onChange={handleInputChange}
                required
              >
                {cat_list.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="action_url">Call to Action URL</label>
              <input
                type="text"
                id="action_url"
                name="action_url"
                placeholder="https://..."
                value={formData.action_url}
                onChange={handleInputChange}
                required
              />
              <p>Users will be able to click on this link</p>
            </div>
            <div className="form-group">
              <label htmlFor="duration">When to post</label>
              <select
                id="action_button"
                name="post_date"
                value={formData.post_date}
                onChange={handlePostTimeChange}
                required
              >
                {post_time.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            {!isApiWorking && (
              <button
                type="button"
                className="saveadbtn"
                onClick={handleSaveAd}
              >
                Save Ad for Later
              </button>
            )}
            {isApiWorking && (
              <button type="button" className="saveadbtn" disabled>
                <i className={apiFaIcon} />
              </button>
            )}
            {isApiWorking && (
              <button type="submit" disabled>
                <i className={apiFaIcon} />
              </button>
            )}
            {!isApiWorking && (
              <button type="submit" onClick={handleSubmit}>
                Submit Ad to Channel
              </button>
            )}

            <div className="autofill">
              <button
                className="defaultbtn"
                type="button"
                onClick={() => handleAutofill("option1")}
              >
                Buy Album
              </button>
              <button
                className="defaultbtn"
                type="button"
                onClick={() => handleAutofill("option2")}
              >
                Come to event
              </button>
              <button
                className="defaultbtn"
                type="button"
                onClick={() => handleAutofill("option3")}
              >
                Subscribe
              </button>
              {props.broadcast.saved_ads.map((ad) => (
                <div key={ad.id} className="savedadbtn">
                  <button
                    type="button"
                    className="userbtn"
                    onClick={() => handleAdClick(ad)}
                  >
                    {ad.title}
                  </button>
                  <button
                    title="Delete Saved Ad"
                    type="button"
                    className="delete-button"
                    onClick={() => handleSavedAdDelete([ad.id])}
                  >
                    <i className="fa fa-times-circle" />
                  </button>
                </div>
              ))}
            </div>
            {!isEmpty(props.broadcast.scheduled_ads) && (
              <ScheduledAdsTable
                scheduled_ads={props.broadcast.scheduled_ads}
                handleAdClick={handleAdClick}
                handleDelete={handleDelete}
              />
            )}
            {/* <PayPalPayForAdButton broadcast={props.broadcast} /> */}
            <AdPreview formData={formData} />
          </form>
        </section>
      </div>
    </div>
  );
};

export default Advertise;

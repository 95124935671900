import React, { useState, useEffect } from "react";
//CSS
import "./TicketPricing.css";

const PAYPAL_PERCENTAGE_FEE = 0.029; // 2.9%
const PAYPAL_FIXED_FEE = 0.3; // $0.30 fixed fee
const SONGDROP_FEE_PERCENTAGE = 0.15; // 15%

//TicketPricing
const TicketPricing = ({ broadcast, onPriceChange }) => {
  //
  const [currency, setCurrency] = useState(broadcast.currency || "GBP");
  const [price, setPrice] = useState(broadcast.current_price || 0);
  const [isFree, setIsFree] = useState(broadcast.current_price === 0);
  const [sellerAmount, setSellerAmount] = useState(0);
  const [paypalFee, setPaypalFee] = useState(0);
  const [songdropFee, setSongdropFee] = useState(0);

  useEffect(() => {
    if (!isFree && price > 0) {
      calculateFees(price);
    }
  }, [price, isFree]);

  const calculateFees = (price) => {
    const songdropFee = price * SONGDROP_FEE_PERCENTAGE;
    const paypalFee = price * PAYPAL_PERCENTAGE_FEE + PAYPAL_FIXED_FEE;
    const sellerAmount = price - songdropFee - paypalFee;

    setPaypalFee(paypalFee.toFixed(2));
    setSongdropFee(songdropFee.toFixed(2));
    setSellerAmount(sellerAmount.toFixed(2));
  };

  const handlePriceChange = (event) => {
    const inputPrice = parseFloat(event.target.value);
    if (!isNaN(inputPrice) && inputPrice > 0) {
      setPrice(inputPrice);
      setIsFree(false);
      if (onPriceChange) {
        onPriceChange(inputPrice); // Call the callback with the new price
      }
    } else {
      setPrice("");
      setIsFree(true);
      if (onPriceChange) {
        onPriceChange(0); // Call the callback with the price set to 0
      }
    }
  };

  const handleCheckboxChange = () => {
    setIsFree(!isFree);
    if (!isFree) {
      setPrice(0);
      setSellerAmount(0);
      setPaypalFee(0);
      setSongdropFee(0);
      if (onPriceChange) {
        onPriceChange(0); // Call the callback with the price set to 0
      }
    }
  };

  return (
    <div className="ticket-pricing">
      <h2>Set Ticket Price</h2>
      <div
        className="input-container"
        data-currency-symbol={getCurrencySymbol(currency)}
      >
        <input
          type="number"
          value={isFree ? "0" : price}
          onChange={handlePriceChange}
          placeholder="Enter price"
          step="0.50"
          min={0}
          disabled={isFree}
        />
      </div>
      <div className="checkbox-container" onClick={handleCheckboxChange}>
        <input
          type="checkbox"
          checked={isFree}
          onChange={handleCheckboxChange}
        />
        <label>Set as Free</label>
      </div>
      {isFree ? (
        <div>
          <h3>Ticket is free</h3>
        </div>
      ) : (
        <div>
          <h3>Price Breakdown</h3>
          <table>
            <tbody>
              <tr>
                <td>Total Price:</td>
                <td>
                  {getCurrencySymbol(currency)}
                  {price}
                </td>
              </tr>
              <tr>
                <td>PayPal Fee:</td>
                <td>
                  {getCurrencySymbol(currency)}
                  {paypalFee}
                </td>
              </tr>
              <tr>
                <td>Songdrop Fee (15%):</td>
                <td>
                  {getCurrencySymbol(currency)}
                  {songdropFee}
                </td>
              </tr>
              <tr>
                <td>Seller Amount:</td>
                <td>
                  {getCurrencySymbol(currency)}
                  {sellerAmount}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <div className="ticket-refund">
        <strong>
          Please read our{" "}
          <a
            href="https://songdrop.live/refund_policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Refund Policy
          </a>{" "}
          before selling tickets on www.songdrop.live
        </strong>
      </div>
    </div>
  );

  function getCurrencySymbol(priceCurrency) {
    const currencySymbols = {
      GBP: "£",
      USD: "$",
      EUR: "€",
    };

    return currencySymbols[priceCurrency] || priceCurrency;
  }
};

export default TicketPricing;
